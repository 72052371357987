.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 1rem;
  margin-bottom: 1rem;
}

.action-card {
  display: flex;
  justify-content: flex-end;
}

.publisher__data {
  display: flex;
}

.ingest__grid {
  margin-left: 10rem;

  .button__ingest {
    color: white;
    background-color: #007DBB;
    margin-left: 1rem;
    height: 3rem;
    margin-top: 2.1rem;
  }
}