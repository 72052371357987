@import './../../../../theme/variables.scss';

.box__document {
  padding: 1.5rem;
  background-color: $background;

  .box__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  .box__configuration {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1.5rem 1rem;
    .document__field {
      flex: 0 1 32%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .box__file__upload {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 32% 30%;
    gap: 2%;
    .value__filed_file {
      height: 3rem;
      margin-left: 2rem;
    }
    iframe {
      width: 210%;
      height: 30rem;
    }
  }
  .value__field {
    float: right;
    box-sizing: border-box;
    height: 3rem;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding: 0rem 0.5rem;
    width: 11.5rem;
  }
  .value__field__title {
    float: right;
    box-sizing: border-box;
    height: 3rem;
    width: 80%;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding: 0rem 0.5rem;
    flex: 10%;
    margin-left: 12%;
  }
  .value__field__name {
    margin-left: 12%;
  }
}
.box__upload__file {
  display: flex;
  align-items: center;
}

.box__buttons__json {
  float: right;
  margin-top: 2rem;
  margin-bottom: 2rem;
  .button__cancel__json__config {
    margin-top: 2rem;
    height: 3rem;
    width: 12rem;
  }
  .button__save__json__config {
    margin-top: 2rem;
    margin-left: 2rem;
    height: 3rem;
    width: 12rem;
  }
}

.box__name {
  display: flex;
  align-items: center;
  width: 80%;
}
.select__type {
  width: 11.5rem;
  height: 3rem;
}
