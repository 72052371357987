.form__financing {
  border: 1px solid grey;
  border-radius: 5px 5px 5px 5px;

  &__fields {
    margin: 1rem;
    display: grid;
    grid-template-columns: 34% 34% 34%;
  }
}

.field__value {
  display: grid;
  margin-bottom: 2rem;
}

.value__field__financing {
  float: right;
  box-sizing: border-box;
  height: 3rem;
  width: 20rem;
  border: 1px solid #9c9c9d;
  border-radius: 10px;
  margin-top: 0.5rem;
  padding: 0px 1rem;
}

.error {
  color: red !important;
}

.button__cancel {
  margin-right: 1rem !important;
  height: 3rem !important;
  width: 8rem !important;
}

.button__save__fin {
  background-color: #007DBB !important;
  height: 3rem !important;
  width: 12rem !important;
}

.branch__drop__finan {
  float: right;
  margin-top: -2.3rem;
  margin-right: -1rem;
}