.value__field__multi {
  box-sizing: border-box;
  height: 3rem;
  width: 10.5rem;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  background-color: #fff;
}
.multiselect__section {
  display: flex;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  background-color: #fff;
  .multiselect__input {
    background-color: none;
    border: none;
    flex: 0 1 32%;
    border-radius: 10px;
    height: 3rem;
  }
  .selected {
    flex: 0 1 32%;
    display: flex;
    border-radius: 7px;
    border: 1px solid;
    align-items: center;
    padding: 0px 5px;
    .button__delete__multi {
      margin: 0px;
      padding: 0px;
    }
    small {
      font-size: 12px;
    }
  }
}
