.card-img {
  max-width: 15rem;
  max-height: 10rem;
}

.form__compo {
  margin-top: -2rem;
  width: 50rem;
  display: grid;

  .form__fields__fist {
    display: flex;
  }

  .form__field {
    margin-top: 1rem;
  }
}

.input__file {
  display: none;
}

.input__file__icon {
  min-width: 2rem;
  min-height: 2rem;
  color: #007DBB;
  margin-top: 3rem;
}

.field {
  width: 10rem;
  height: 2rem;
}

.buttons__actions {
  margin-top: 2rem;
  float: right;
}

.button__save__compo {
  background-color: #007DBB !important;
  margin-top: 4rem !important;
  position: absolute !important;
  margin-left: 48% !important;
  height: 3rem !important;
  width: 12rem !important;
}

.value__field__component {
  height: 3.5rem;
  width: 16rem;
  border: 1px solid #9c9c9d;
  border-radius: 10px;
  margin-top: 0.5rem;

  &__large {
    height: 3.5rem;
    width: 40rem;
    border: 1px solid #9c9c9d;
    border-radius: 10px;
    margin-top: 0.5rem;
  }
}

.table__label__compo {
  margin-top: 1rem;
  display: flex;
}

.drop-container {
  margin-left: 2rem;
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 480px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color 0.2s ease-in-out;
}

input[type='file'] {
  width: 500px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #555;
}

input[type='file']::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #007DBB;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

input[type='file']::file-selector-button:hover {
  background: #0d45a5;
}

.picture__section {
  display: flex;
}

.drop__image__document {
  margin-left: 1rem;
}

.field__title {
  margin-left: 2rem;
}

.button__save__component {
  margin-left: 50%;
  margin-top: 3rem;

  .button__save__task {
    width: 12rem;
    height: 3rem;
  }
}