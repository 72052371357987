$background-color-left: black;
$background-color-right: white;
$text-color-left: white;
$text-color-right: black;
$button-color: #007DBB;
$font-stack: "Roboto", "Helvetica", "Arial", sans-serif;
$error-color: red;
$success-color: green;

.login-container {
    display: flex;
    width: 100%;
    height: 100vh;

    .login {
        width: 65%;
        background-color: $background-color-left;
        color: $text-color-left;
        font-family: $font-stack;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        height: 100%;

        .from-title,
        .maintenance-screens {
            text-transform: uppercase;
            margin-bottom: 10px;
        }

        .maintenance {
            margin-right: 2rem;
            animation: slideUp 6s ease forwards infinite;
            font-size: 30px;
            font-weight: bold;
        }

        .screens {
            animation: slideDown 6s ease forwards infinite;
            font-size: 30px;
            font-weight: bold;
        }
    }

    .login-fields {
        width: 35%;
        background-color: $background-color-right;
        color: $text-color-right;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        height: 100%;

        input {
            width: 80%;
            margin-bottom: 30px;
            padding: 20px;
            border: 1px solid $text-color-right;
            border-radius: 5px;

            &::placeholder {
                color: rgba($text-color-right, 0.5);
            }

            &:focus {
                outline: none;
                border-color: darken($button-color, 10%);
            }

            &.success {
                border-color: $success-color;
            }
        }


        button {
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            background-color: $button-color;
            color: $background-color-right;
            cursor: pointer;
            transition: background-color 0.3s ease;
            width: 10rem;
            font-size: 1.2rem;
            font-weight: bold;

            &:hover {
                background-color: darken($button-color, 10%);
            }

            &:disabled {
                background-color: #ccc;
                color: #666;
                cursor: default;
            }
        }
    }
}

.input-error {
    border-color: $error-color !important;
    color: $error-color !important;
}

.logo {
    width: 30rem;
    height: 30rem;
}

.title {
    display: flex;
    flex-direction: row;
}

.error-message {
    margin-top: 2rem;
    color: $error-color;
    font-weight: 500;
}

@keyframes slideUp {

    0%,
    100% {
        transform: translateY(100%);
        opacity: 0;
    }

    20%,
    80% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideDown {

    0%,
    100% {
        transform: translateY(-100%);
        opacity: 0;
    }

    20%,
    80% {
        transform: translateY(0);
        opacity: 1;
    }
}

.login-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: green;
    font-size: 20px;
    font-weight: bold;
    width: 35%;
    background-color: $background-color-right ;
    height: 100%;

    .success-icon {
        margin-right: 10px;
        color: green;

    }



    ;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    margin-left: 3rem;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}