.navbar {
  display: flex;
  justify-content: flex-end;
}

.user__name {
  font-size: 1.1rem;
  font-weight: 600;
  margin-right: 0.5rem;
}

.spinner-logout {
  width: 1rem;
  height: 1rem;
  margin-right: 3rem;
  margin-left: 1rem;
}