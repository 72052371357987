.button__cancel__delete__dialog {
  margin-right: 1rem !important;
  height: 3rem !important;
  width: 10rem !important;
}

.button__delete__delete__dialog {
  background-color: #007DBB !important;
  height: 3rem !important;
  width: 10rem !important;
}

.dialog__delete {
  width: 30rem !important;
  height: 10rem !important;
}

.buttons__actions__delete {
  margin-right: 2rem;
}

.dialog__delete__confirm {
  background-color: whitesmoke !important;
  width: 30rem !important;
}