.form__promo {
  border: 1px solid grey;
  border-radius: 5px 5px 5px 5px;

  &__fields {
    margin: 1rem;
    display: grid;
    grid-template-columns: 50% 50%;
  }
}

.field__value__promo {
  display: grid;
  margin-bottom: 2rem;
}

#field {
  float: right;
  margin-right: 2rem;
}

.value__field__promo {
  float: right;
  box-sizing: border-box;
  height: 3rem;
  width: 20rem;
  border: 1px solid #9c9c9d;
  border-radius: 10px;
  margin-top: 0.5rem;
  padding: 0px 1rem;
}

.error {
  color: red !important;
}

.button__cancel {
  margin-right: 1rem !important;
  height: 3rem !important;
  width: 8rem !important;
}

.button__save__promo {
  background-color: #007DBB !important;
  height: 3rem !important;
  width: 12rem !important;
}

.select__promo {
  width: 20rem;
  margin-top: 0.5rem;
}

.chip {
  background-color: #007DBB;
}

.highlightOption {
  background-color: #007DBB;
}

.searchWrapper {
  min-height: 3rem !important;
  border: 1px solid #9c9c9d !important;
  border-radius: 10px !important;
}