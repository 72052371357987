.select__document {
  width: 12rem;
  margin-left: -2rem;
}
.select__financing {
  width: 20rem;
  margin-top: 0.5rem;
}
.select__general {
  width: 20rem;
}
