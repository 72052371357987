.gtc-scheduler {
    padding: 20px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    .scheduler-form {
        .form-row {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            .day-label {
                color: black;
                font-size: 20px;
                font-weight: 700;
                line-height: 36px;
                word-wrap: break-word;
                margin-right: 5vw;
            }

            .time-select {
                padding: 10px;
                font-size: 16px;
                border: 1px solid #ddd;
                border-radius: 6px;
                background-color: #fff;
                cursor: pointer;
                width: 15vw;
            }

            label {
                font-weight: 500;
            }
        }

        .save-button {
            color: white;
            background-color: #027DBA;
            padding: 12px 30px;
            font-size: 16px;
            border: none;
            border-radius: 20px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            cursor: pointer;
            transition: background-color 0.3s, box-shadow 0.3s;
            width: 15rem;
            font-weight: 600;
            margin-top: 3rem;
        }

        .save-button:hover {
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
            background-color: #027DBA;
        }

        .save-button:active {
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            background-color: #027DBA;
        }
    }

    .text-gray {
        color: #808080;
    }
}

.scheduler-header {
    margin-bottom: 3rem;
    width: 40vw;
    text-align: left;

    h1 {

        color: black;
        font-size: 40px;
        font-weight: 700;
        line-height: 56px;
        word-wrap: break-word
    }

    p {
        color: black;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        word-wrap: break-word
    }
}

.completed-updates-container {
    margin-top: 20px;
    font-family: 'Arial', sans-serif;


    h2 {
        color: black;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        word-wrap: break-word
    }

    .completed-updates-table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;

        thead {
            tr {
                background-color: #262222;
                text-align: center;
            }

            th:first-child {
                border-top-left-radius: 10px;
            }

            th:last-child {
                border-top-right-radius: 10px;
            }

            th {
                color: white;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                word-wrap: break-word;
                height: 3rem;
            }
        }

        tbody {
            tr {
                transition: background-color 0.3s ease;
                text-align: center;

                &:hover {
                    background-color: #f0f0f0;
                }

                &.detail-row {
                    background-color: #f9f9f9;
                }

                td {
                    padding: 15px;
                    border-bottom: 1px solid #e1e1e1;

                    color: #555;
                    cursor: pointer;

                }
            }

            tr:last-child {
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;

                td:first-child {
                    border-bottom-left-radius: 15px;


                }

                td:last-child {
                    border-bottom-right-radius: 15px;

                }
            }
        }
    }




    .failed {
        color: red;
        background-color: #fce9ea;
        padding: 4px 15px;
        border-radius: 8px;
        font-weight: bold;
        text-align: center;
        font-size: 13px;
    }
}

.date-cell {
    width: 10rem;
    display: flex;
    flex-direction: row;
    font-weight: bold;
}

.pagination-container {
    text-align: right;
    margin-top: 2rem;
}

.pagination-button {
    background-color: #007DBB;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pagination-button:hover {
    background-color: #007DBB;
}

.pagination-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.status-label {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 9px;
    font-weight: bold;
    text-align: center;
    width: 150px;
    font-size: 13px;

    &.complete {
        background-color: #c8e6c9;
        color: #256029;
        width: 10rem;
    }

    &.partial {
        background-color: #e9f0f5;
        color: #053F73;
        width: 10rem;
    }

    &.failed {
        color: red;
        background-color: #fce9ea;
        width: 10rem;
    }

    &.progress {
        background-color: #FED88D;
        color: #BA4A00;
        width: 10rem;
    }


}

.white-background td {
    background-color: #ffffff;
    border: none !important;
    cursor: auto !important;
}

.gray-background td {
    background-color: #f8f8f8 !important;
    border: none !important;
    cursor: auto !important;
}

.completed-updates-table tr:nth-child(odd) {
    background-color: #ffffff;
}

.completed-updates-table tr:nth-child(even) {
    background-color: #f8f8f8;
}

.save-button-disabled {
    background-color: gray;
    color: white;

}