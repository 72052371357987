.title {
  margin-bottom: 3rem;
}
.input__filter {
  margin-bottom: 2rem;
  width: 400%;
}
.box__filter {
  margin-bottom: 2rem;
  position: absolute;
  left: 18rem;
  top: 14rem;
}
