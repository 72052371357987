.form {
  border: 1px solid grey;
  border-radius: 5px 5px 5px 5px;
  &__fields {
    margin: 1rem;
    display: grid;
    grid-template-columns: 34% 34% 34%;
  }
  .components__field{
    margin: 1rem;

  }
  .field__container {
    display: grid;
    margin-bottom: 2rem;
    .field {
      float: right;
      box-sizing: border-box;
      height: 3rem;
      width:  92%;
      border: 1px solid #9c9c9d;
      border-radius: 10px;
      margin-top: 0.5rem;
      padding: 0px 1rem;
    }
  }

}
.buttons__actions {
  margin-top: 2rem;
  float: right;
}