.formik__data {
  width: 35rem;
  background-color: whitesmoke;
}

.description__data {
  width: 20rem;
  height: 3rem;
  margin-top: 1rem;
}

.button__cancel__comment {
  margin-right: 1rem !important;
  height: 3rem !important;
  width: 10rem !important;
}

.buttons__actions__comment {
  display: flex;

  margin: 1rem;
  margin-top: 3rem;
}

.button__publish__comment {
  background-color: #007DBB !important;
  height: 3rem !important;
  width: 12rem !important;
}

.dropdown__branch {
  margin-bottom: 1rem;
}

.buttons__actions {
  right: 0;
}

.value__field__desc {
  box-sizing: border-box;
  height: 3rem;
  width: 32rem;
  border: 1px solid #9c9c9d;
  border-radius: 10px;
  margin-top: 2rem;
  padding: 0px 1rem;
}