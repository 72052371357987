.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: whitesmoke;
  border: 2px solid grey;
  box-shadow: 5rem;
  border-radius: 1rem;
  padding: 4rem 4rem 4rem 4rem;
  height: 50rem;
  .button__close__component {
    margin-left: 25%;
    height: 3rem;
    width: 12rem;
    margin-top: -4.5rem;
  }
}
