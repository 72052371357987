.jsonEditor {
  width: 100%;
  height: 30vh;
}
.button__cancel__json {
  height: 3rem;
  width: 8rem;
}
.button__save__json {
  margin-left: 2rem !important;
  height: 3rem;
  width: 8rem;
}
.buttons__json__edit {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
}
