.header__sidebar {
  background-color: black;
  color: white;
  padding-left: 1rem;
  font-weight: 600;
}
.sidebar__cdx {
  background-color: black;
  color: white;
  font-weight: 600;
}

.sidebar__cdx__submenu {
  background-color: black;
  color: white;
  font-weight: 100 !important;
}
.css-1wvake5 {
  border-color: black !important;
}
.css-dip3t8 {
  background-color: black !important;
}
