.grid__header__financing {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0;
  margin-top: 0;
}
.button__add__financing {
  height: 3rem;
  margin-top: 2rem !important;
}
