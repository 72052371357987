.grid__box {
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
}

.MuiButtonBase-root:disabled {
  cursor: not-allowed;
  pointer-events: auto;
  background-color: lightgray;
}

.dropdown__branch {
  display: flex;
}

.buttons__ready {
  display: flex;
  justify-content: flex-end;

  .button__ready__publish {
    margin-right: 1rem;
    color: #007DBB !important;
  }

  .button__ready__unpublish {
    color: #007DBB !important;
  }
}

.grid__data {
  width: 100%;
  margin-top: 1rem;
  height: 100%;
}